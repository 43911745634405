/*Medidas generales que se reutilizan en el diseño*/
/*Incluye algunas medidas que estes usando con frecuencia*/

:root {
  /*colores verdes*/
  --grid-color-blue: #004040;
  --color-blue: #0e8c8c;
  --color-green-boletin: #006666;
  --color-green-steps: #007f7f;
  --color-blue-bajo: #98d2de;
  --color-boton: #a3e2ba;
  /*colores blancos*/
  --color-white: #fbfbfb;
  --color-white-light: #ffffff;
  /*colores grices*/
  --color-grey: #3e3e3e;
  --color-grey-border: #bebed2;
  --color-grey-border-img: #ebebeb;
  --color-separacion-header: #dbdbdb;
  --color-texts: #707070;
  --color-border-input: #a7a7a7;
  --color-gray-bajo: #f2f2f2;
  /*colores rosas*/
  --color-borde-redireccion: #99125c;
  --color-morado: #800d61;
  --color-pink: #d47f7f;

  /*medidas, color y bordes de los botones*/
  --height-boton: 1.9em;
  --width-boton: 6.87em;
  --border-radius-boton: 0.32em;
  --border-boton: none;
  --align-boton: center;
  --top-boton: 0.06em;
  --border-line: solid 0.06em #a7a7a7;
  --borde-steps: solid 0.2em #007f7f;
  --boxShadow-dash: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
  --boxShadow-button: 0px 1px 6px 0px rgba(0, 0, 0, 0.5);
  /*css contenedores perfil y view boletines*/
  --width-conten-view: 45em;
  --height-conten-view: 41em;
  --margin-conten: 2em;
  /* Imgs*/
}

.conteiner {
  display: grid;
  grid-template: 3.12em auto/auto 1fr;
  grid-template-areas: 'aside header' 'aside section';
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}

.aside {
  grid-area: aside;
  background-color: var(--grid-color-blue);
  width: 12.5em;
  transition: all 1s;
  overflow: hidden;
}

.header {
  grid-area: header;
  background-color: var(--color-white-light);
  display: flex;
  color: var(--color-grey-border);
  overflow: hidden;
  border-bottom: var(--border-line);
}

.section {
  grid-area: section;
  background-color: var(--color-white);
  overflow-x: hidden;
}

.footer-content {
  grid-area: footer;
  text-align: center;
  margin-top: 2em;
  color: var(--color-grey);
}

h2 {
  color: var(--color-grey);
  border-bottom: 0em;
}

.btn {
  text-align: var(--align-boton);
  width: var(--width-boton);
  height: var(--height-boton);
  background-color: var(--color-blue);
  color: var(--color-white);
  border-radius: var(--border-radius-boton);
  color: var(--color-white);
  border: var(--border-boton);
  padding-top: var(--top-boton);
}

.btn:hover {
  background-color: var(--color-blue);
  opacity: 0.7;
  color: var(--color-white);
}

@media only screen and (max-width: 600px) {
  .conteiner {
    height: 92.5vh;
  }
}
@media (min-width: 600px) and (max-width: 991px) {
  .conteiner {
    height: 92vh;
  }

  .footer-content {
    margin-top: -3em;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .footer-content {
    margin-top: -3em;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(218, 218, 218);
  border: 4px solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
