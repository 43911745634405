/*estilos del componente login*/
/*container general componente login*/
.container-general-login {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var(--grid-color-blue);
  overflow: hidden;
}

/*contenedor login*/
.row-login {
  display: grid;
  margin-top: 100px;
  width: 50em;
  grid-template-areas: 'tituloL sesion';
}

/*contenedor titulo, logo, img*/
.container-titulo {
  grid-area: tituloL;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  background-color: var(--color-white-light);
  width: 17.5em;
  height: 31.25em;
  border-right: 2px solid var(--color-blue);
  z-index: 20;
}

.container-sesion {
  grid-area: sesion;
  display: grid;
  flex-wrap: wrap;
  background-color: white;
  width: 32.5em;
  height: 31.25em;
  color: var(--color-blue);
  box-sizing: border-box;
  position: relative;
}

.text-conten {
  font-size: 20px;
}
.titular-inicio {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey);
  margin-top: 1em;
}

.sub-titulo {
  font-size: 20px;
  display: flex;
  color: var(--color-grey);
  margin-top: 0em;
  height: 30px;
}

.logotn {
  margin-top: 3em;
  height: 9em;
  width: 9em;
}

.logoW {
  display: none;
  width: 7em;
  height: 7em;
}

.miImg {
  margin-left: 10em;
  margin-top: 3em;
  margin-bottom: 0;
  width: 26.68em;
  height: 12.5em;
  z-index: 10;
}

.panel-default {
  position: absolute;
  color: red;
  margin-top: 26em;
}

.text-inicio {
  text-align: left;
  margin-left: 2em;
  margin-top: 4em;
  color: #007f7f;
}

.p-conten {
  font-size: 14px;
  width: 40%;
}

.login {
  font-size: 14px;
  width: 100%;
  margin-top: -1.5em;
  box-sizing: border-box;
}

.form {
  margin: 2.5em;
  width: 37%;
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;
}

.conten-inputs {
  border-bottom: 1px solid #bebed2;
  padding-left: 1.5em;
}

.input-login {
  width: 100%;
  margin-top: 0.5em;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  transition: all 0.3s;
}

.img-correo {
  width: 1.25em;
  height: 1em;
  margin-top: -1.5em;
  margin-left: 23em;
  margin-bottom: 1em;
}

.img-contraseña {
  width: 1em;
  height: 1.31em;
  margin-top: -1.8em;
  margin-left: 23em;
  margin-bottom: 1em;
}

.text-login {
  content: attr(type);
  display: flex;
  margin: 2em 0 0;
  text-align: left;
  font-size: 0.9em;
  color: #5a5a5a;
}

.custom-control {
  margin-top: 1em;
}

.btn-login {
  margin-top: 1em;
  float: right;
  margin-left: 58em;
}

.a-forgot {
  color: var(--color-grey);
  background-color: transparent;
  border: none;
}

.miImg2 {
  display: none;
}

.footer {
  height: 3.12em;
  color: var(--color-white-light);
  font-size: 0.7em;
  opacity: 0.9;
  padding: 5em;
}

/*Media querie la cual controla el responsive para dispositivos moviles */
@media only screen and (max-width: 600px) {
  .container-general-login {
    height: 92.5vh;
  }
  .row-login {
    grid-template-areas: 'tituloL' 'sesion';
    width: 100vw;
    height: 100vh;
  }

  .container-titulo {
    background-color: var(--grid-color-blue);
    width: 100vw;
    height: 120px;
    margin-top: 0em;
    display: grid;
  }

  .titular-inicio {
    color: var(--color-white-light);
    margin-top: 2.3em;
  }

  .sub-titulo {
    color: var(--color-white-light);
    margin-left: 0.4em;
    margin-top: 0em;
  }

  .container-sesion {
    margin-top: 0em;
    width: 100vw;
    min-height: 85vh;
    font-size: 1.5em;
    grid-template-rows: 20% 30%;
  }

  .text-inicio {
    margin: 1.5em;
    height: 50%;
    width: 71%;
  }

  .p-conten {
    width: 100%;
  }

  .form {
    width: 80vw;
  }

  .logotn {
    display: none;
  }

  .miImg {
    display: none;
  }

  .miImg2 {
    display: unset;
    margin-left: 1em;
    margin-top: -1em;
  }
}

/*Media querie la cual controla el responsive para dispositivos medianos Ipad */
@media (min-width: 600px) and (max-width: 991px) {
  .row-login {
    grid-template-areas: 'tituloL' 'sesion';
    width: 100vw;
    height: 100vh;
  }

  .container-titulo {
    background-color: var(--grid-color-blue);
    height: 200px;
    width: 100vw;
    font-size: 2em;
    display: grid;
  }

  .titular-inicio {
    margin-top: 1em;
    font-size: 1.5em;
    color: var(--color-white-light);
  }

  .sub-titulo {
    font-size: 1em;
    color: var(--color-white-light);
    margin-left: 0.3em;
  }

  .logotn {
    display: none;
  }

  .logoW {
    display: unset;
    font-size: large;
    position: absolute;
    margin-left: 4em;
    margin-top: 2.5em;
  }

  .container-sesion {
    width: 220vw;
    height: 100vh;
    font-size: 18px;
    grid-template: 200px/ 1fr;
    grid-template-rows: 200px 360px;
    grid-template-areas: 'textI' 'login';
  }

  .text-inicio {
    grid-area: textI;
    margin-top: 1em;
    padding: 2em;
  }

  .login {
    grid-area: login;
    width: 100%;
    font-size: 16px;
    margin-top: 0;
  }

  .form {
    margin-top: 0em;
    margin-left: 4em;
  }

  .conten-inputs {
    margin-bottom: 2em;
  }

  .text-login {
    margin-bottom: 1em;
  }

  .input-login {
    margin-bottom: 0.5em;
  }
  .img-correo {
    margin-left: 33em;
  }

  .img-contraseña {
    margin-left: 33em;
  }

  .miImg {
    display: none;
  }

  .miImg2 {
    display: unset;
    margin-left: 5em;
    margin-bottom: 1em;
  }
}

.loading-container {
  background: #007f7f;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 17;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  animation: loading-sesion 0.3s forwards;
  opacity: 0;
  flex-direction: row;
}
.loading-container span {
  margin-left: 10px;
  font-size: 14px;
}

@keyframes loading-sesion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Media querie la cual controla el responsive para dispositivos grandes Ipad-pro */
@media (min-width: 992px) and (max-width: 1024px) {
  .row-login {
    width: 100vw;
    height: 100vh;
    margin-top: 15em;
    margin-left: 13em;
  }

  .container-sesion {
    margin-left: -14em;
  }

  .miImg {
    margin-top: 4em;
  }

  .footer {
    margin-left: -20em;
  }
}
