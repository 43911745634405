/*Diseño menu hamburguesa*/
.hamburger{
  grid-area: hamburguer;
	position:fixed;
  background-color:transparent;
	left:0;
  top:0;
	padding: 1.25em .812em;
	-webkit-transform:translate3d(0, 0, 0);
	transform:translate3d(0, 0, 0);
	-webkit-transition:-webkit-transform 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
	transition:transform 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
	z-index:1002;
	cursor:pointer;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none
  }


._layer{
	background:var(--color-white);
	margin-bottom: .25em;
	border-radius: .125em;
	width: 1.75em;
	height: .25em;
	opacity:1;
	-webkit-transform:translate3d(0, 0, 0);
	transform:translate3d(0, 0, 0);
	-webkit-transition:all 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
	transition:all 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
  }

.hamburger:hover .-top{
	-webkit-transform:translateY(-100%);
	-ms-transform:translateY(-100%);
	transform:translateY(-100%);
  } 

.hamburger:hover .-bottom{
	-webkit-transform:translateY(100%);
	-ms-transform:translateY(100%);
	transform:translateY(100%);
  }
  
.hamburger.is-active .-top{
	-webkit-transform:translateY(200%) rotate(45deg) !important;
	-ms-transform:translateY(200%) rotate(45deg) !important;
	transform:translateY(200%) rotate(45deg) !important;
  }

.hamburger.is-active .-mid{
	opacity:0;
  }

.hamburger.is-active .-bottom{
	-webkit-transform:translateY(-200%) rotate(135deg) !important;
	-ms-transform:translateY(-200%) rotate(135deg) !important;
	transform:translateY(-200%) rotate(135deg) !important;
  }

 /*Contenedor general menú*/
 
 /*función para restar el div del btn out*/
.menu-toggle {
  min-height: calc(100vh - 50px);
}

 /*Contendor del titulo del menú y btn configuración*/
.contenedor-titulo {
  display: grid;
  grid-template-areas: "hamburguer";
  color: var(--color-white);
  height: 70px;
  }

  /*Contenedor diseño links menú incluyendo btn out*/
.nav-menu {
  display: grid;
  grid-template-areas: "logo" "links";
  grid-template-rows: 7em auto;
  }

  .div-link {
    grid-area: links;
    display: grid;
    grid-template-areas: "dashboard" "boletines" "listado" "perfil";
  }

.L-dashboard {
 grid-area: dashboard;
} 

.L-boletines {
 grid-area: boletines;
}

.L-listado {
  grid-area: listado;
 }

.L-miperfil {
 grid-area: perfil;
}

/*Contenedor img Telenet*/
.div-logoW {
  grid-area: logo;
  text-align: center;
}

.logoW-content {
  height: 7em;
  width: 7em;
}

.Link {
  margin: 1em;
  color: var(--color-white);
  display: inline-flex; 
  transition: 0.3s;
  }

.material-icons .md-24 {
  line-height: .5em;
  }
  
.textMenu {
  font-size: 14px;
  margin-left: 1.5em;
  margin-top: .3em;
  width: 8.4em;
  }

.conten-boton {
  height: 1.87em;
  margin-top: -4.9em;
  }

.conteiner-btnOut {
  height: 50px;
  }

.link-out {
  background-color: transparent;
  border: none;
}

.textOut {
  margin-left: -2em;
}

  @media only screen and (max-width: 600px) {
    .aside {
      width: 3.44em;
      height: 92vh;
    }
    .menu-toggle {
      min-height: calc(90vh - 50px);
      overflow-y: hidden;
    }

    
  }

  @media(min-width:600px) and (max-width: 991px) {
    .aside {
      width: 3.44em;
      height: 94vh;
    }

    .nav-menu {
      margin-top: 2em;
    }
    .menu-toggle {
      min-height: calc(92vh - 50px);
    }

  }

  @media(min-width:600px) and (max-width: 991px) {
    .aside {
      width: 3.44em;
      height: 92.5vh;
    }

    .menu-toggle {
      min-height: calc(89vh - 50px);
    }
  }

  @media(min-width:992px) and (max-width: 1024px) {
    .aside {
      width: 3.44em;
      height: 100vh;
    }

    .menu-toggle {
      height: calc(80vh - 50px);
    }
  }

  