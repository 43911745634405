/*Contenedores principales*/
.contenedor-view {
  /* overflow: hidden; */
}

.contenido-view {
  display: flex;
  min-height: calc(100vh - 50px);
  justify-content: center;
}

/*Inicio diseño contenedor vista boletin*/
.view {
  grid-area: view;
  display: grid;
  grid-template-areas: 'titulo titulo' 'input input' 'problema solucion';
  margin: var(--margin-conten);
  background-color: var(--color-white-light);
  min-width: 70vw;
  height: var(--height-conten-view);
  color: var(--color-grey);
  overflow: hidden;
  border-radius: var(--border-radius-boton);
  box-shadow: var(--boxShadow-dash);
  /* border: 1px solid var(--color-morado); */
}

/*Diseño grid titulo*/
.titulo-view {
  grid-area: titulo;
  display: inline-flex;
}

#rectangle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  margin-left: 0;
  background: var(--color-green-boletin);
  padding: 0px 10px 0px 10px;
}

.h3-bol {
  color: var(--color-white-light);
}
.shareButton {
  color: var(--color-white-light);
  font-size: 14px;
  background: #0d9494;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s;
}
.material-icons-share {
  margin-left: 10px;
  vertical-align: middle;
}
.shareButton:hover {
  box-shadow: var(--boxShadow-button);
}
/*Diseño grid informacion de inputs*/
.input-view {
  grid-area: input;
  display: grid;
  grid-template-areas:
    'proyecto cliente'
    'fecha sistema'
    'equipo marca'
    'modelo linkVideo';
  margin-top: 0em;
  font-size: 16px;
}

.proyecto {
  grid-area: proyecto;
}
.input-proyecto {
  margin-left: 0.5em;
}

.cliente {
  grid-area: cliente;
}
.input-cliente {
  margin-left: 1em;
}

.fecha {
  grid-area: fecha;
}
.input-fecha-vista {
  margin-left: 1.8em;
}

.sistema {
  grid-area: sistema;
}
.input-sistema {
  margin-left: 0.6em;
}

.div-input {
  display: inline-flex;
  margin-left: 2em;
}

.equipo {
  grid-area: equipo;
}

.input-equipo {
  margin-left: 1.3em;
}

.modelo {
  grid-area: modelo;
}

.input-modelo {
  margin-left: 1em;
}

.marca {
  grid-area: marca;
}

.input-marca {
  margin-left: 1.3em;
}
.linkVideo {
  grid-area: linkVideo;
}
.input-linkVideo {
  margin-left: 1.3em;
}

.input-bol {
  width: 40%;
  height: 2em;
  border-radius: var(--border-radius-boton);
}

/*Diseño grid contenedores descripción e imagen*/
.problema-view {
  grid-area: problema;
  display: inline-flex;
  justify-content: space-around;
}

.solucion-view {
  grid-area: solucion;
  display: inline-flex;
  justify-content: space-around;
}

.conteiner-problema {
  display: block;
  margin-bottom: 50px;
  padding: 10px;
}

.titulo-problem {
  background-color: var(--color-green-boletin);
  width: 14.38em;
  height: 25px;
  color: var(--color-white-light);
  text-align: center;
  border-radius: var(--border-radius-boton);
}

.input-problema {
  width: 100%;
  height: 10em;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #cacaca;
  border-radius: var(--border-radius-boton);
  resize: none;
}

.material-icons.md-56 {
  font-size: 120px;
  color: var(--color-grey-border-img);
  margin-top: 0.1em;
}

.img-problema {
  width: 100%;
  height: 100%;
  position: relative;
}
.imgBase64 {
  position: absolute;
  width: 100%;
  object-fit: cover;
  /* width: 200px; */
}
/*Inicio diseño contenedor comentarios*/
.view-coment {
  grid-area: view2;
  display: grid;
  grid-template-areas: 'titulo-coment' 'contador' 'comentar' 'comentarios';
  grid-template-rows: 5em 90px 130px auto;
  background-color: var(--color-white-light);
  border: 1px solid var(--color-morado);
  width: 30vw;
  height: var(--height-conten-view);
  margin: var(--margin-conten);
  color: var(--color-grey);
  overflow-x: hidden;
  border-radius: var(--border-radius-boton);
  box-shadow: var(--boxShadow-dash);
}

.titulo-coment {
  grid-area: titulo-coment;
  width: auto;
  height: 5em;
  margin-left: 0;
  background: var(--color-green-boletin);
}

.div-contador {
  grid-area: contador;
  width: 26vw;
  height: 50px;
  margin-left: 2em;
  margin-top: 2em;
  border-top: solid 1.5px var(--color-grey-border-img);
  border-bottom: solid 1.5px var(--color-grey-border-img);
}

.input-contador {
  margin-left: 1em;
  color: var(--color-border-input);
}

.material-icons.md-5 {
  margin-right: 0.5em;
  margin-top: 0.4em;
}

.div-comentar {
  grid-area: comentar;
  display: flex;
  margin-top: 2em;
  flex-direction: column;
  align-items: center;
}

.input-comentar {
  width: 20em;
  border-radius: var(--border-radius-boton);
  border: solid 1.5px var(--color-grey-border-img);
}

.btn-comentar {
  margin-top: 1em;
}

.div-comentarios {
  grid-area: comentarios;
}

.div-comentario {
  width: 26vw;
  margin-left: 2em;
  margin-top: 2em;
  border-bottom: solid 1.5px var(--color-grey-border-img);
}

@media only screen and (max-width: 600px) {
  .contenido-view {
    height: auto;
    grid-template-areas: 'view' 'view2';
    overflow: auto;
  }

  .view {
    margin-left: 1em;
    width: auto;
    height: fit-content;
  }
  .div-input {
    display: block;
    margin-top: 0.5em;
  }
  .input-view {
    margin-left: 0em;
  }
  .input-view {
    grid-template-areas: 'proyecto' 'cliente' 'fecha' 'sistema' 'equipo' 'marca' 'modelo';
  }

  .input-bol {
    margin-left: 0em;
    width: 13em;
  }

  .problema-view {
    display: block;
    margin-left: 1em;
    margin-top: 1em;
  }

  .solucion-view {
    display: block;
    margin-left: 1em;
    margin-top: 1em;
  }

  .img-problema {
    width: 12em;
    margin-left: 1em;
  }

  /*diseño div comentarios*/
  .view-coment {
    width: 75vw;
    height: 60vh;
    margin-left: 1em;
    margin-top: 1em;
  }

  .h3-bol {
    margin-left: 1.5em;
  }

  .div-contador {
    width: 65vw;
    margin-left: 1em;
  }
  .input-comentar {
    width: 13em;
  }

  .div-comentario {
    width: 65vw;
    margin-left: 1em;
  }

  .footer-content {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 991px) {
  .contenido-view {
    height: auto;
  }

  .contenido-view {
    grid-template-areas: 'view' 'view2';
    overflow: auto;
  }

  .view {
    width: auto;
  }

  .input-view {
    grid-template-areas:
      'proyecto cliente'
      'fecha sistema'
      'equipo marca'
      'modelo modelo';
  }

  .view-coment {
    width: 85vw;
    height: 40vh;
  }

  .titulo-coment {
    text-align: center;
  }

  .div-contador {
    width: 75vw;
  }

  .div-comentarios {
    width: 75vw;
  }

  .div-comentario {
    width: 75vw;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .contenido-view {
    grid-template-areas: 'view' 'view2';
    overflow: auto;
  }

  .view {
    width: auto;
  }

  .input-view {
    grid-template-areas:
      'proyecto cliente'
      'fecha sistema'
      'equipo marca'
      'modelo modelo';
  }

  .view-coment {
    width: 89vw;
    height: 30vh;
    margin-top: 0em;
  }

  .div-contador {
    width: 83vw;
  }

  .input-comentar {
    width: 30em;
  }

  .div-comentario {
    width: 83vw;
  }
}
