#contenido {
    background-color: var(--color-white-light);
}

.contenedor-perfil {
    height: 100vh;
    overflow: hidden;
}

.contenido-perfil {
    overflow: hidden;
}

.contenedor-vista {
    display: flex;
    justify-content: center;
    height: 75vh;
}


.cont-perfil {
    grid-area: vista;
    display: grid;
    grid-template-areas: "morado" "info";
    width: 330px;
    height: 330px;
    overflow: hidden;
    box-shadow: var(--boxShadow-dash);
    margin-left: 2em;
    margin-top: 80px;
    margin-bottom: 2em;
    border-radius: var(--border-radius-boton);
}

.div-morado {
    grid-area: morado;
    background-color: var(--color-blue-bajo);
    height: 70px;
    margin-bottom: 0em;
    border-bottom: 1px;
}

.div-avatar {
    text-align: center;
    border-radius: 50%;
    width: 6em;
    height: 6em;
    background-color: var(--color-white);
    margin: auto;
    margin-top: 1em;
    border: 1px solid var(--color-grey-border);
}

.material-icons.md-51 {
    font-size: 6em;
    color: var(--color-grey-border);
}

.div-nombre {
    margin-left: 4em;
    color: var(--color-grey);
    text-align: center;
    border-bottom: 1px solid var(--color-blue-bajo);
    width: 60%;
}

.div-info {
    grid-area: info;
    margin-top: 6em;
}

.material-icons.md-25 {
    color: var(--color-blue);
    margin-left: 1em;
}

.correo-time {
    display: inline-flex;
    margin-top: 1em;
}
.p-correo-time {
    margin-left: 1em;
    font-size: 15px;
}

@media only screen and (max-width: 600px) {
    .contenedor-perfil {
        height: 92vh;
        width: auto;
    }

    .contenedor-vista {
        width: auto;
    }

    .cont-perfil {
        margin-left: 1em;
        width: 250px;
        height: 250px;
    }

    .div-avatar {
        margin-left: 4.7em;
    }

    .material-icons.md-51 {
        font-size: 5.8em;
        margin-top: 0.02em;
    }

    .div-nombre {
        margin-left: 3em;
    }

    .h4-nombre {
        font-size: 22px;
    }

    .h5-area {
        font-size: 15px;
    }

    .p-correo-time {
        margin-left: 1em;
        font-size: 12px;
    }
    .correo {
        margin-top: 1em;
    }

    .time {
        margin-top: 0em;
    }
   .material-icons.md-25 {
       margin-left: 0.5em;
   }
}

@media(min-width:600px) and (max-width: 991px) {

}

@media(min-width:992px) and (max-width: 1024px) {
    
}