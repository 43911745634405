.react-tagsinput {
  background-color: #fff;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 5px;
  position: relative;
  max-width: 650px;
}

.react-tagsinput--focused {
  border-color: var(--grid-color-blue);
}

.react-tagsinput-tag {
  background-color: var(--color-blue);
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  border-radius: 5px;
  position: relative;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  /* border: 0; */
  border: 1px solid var(--color-border-input);
  color: #777;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  position: relative;
}
