/*Diseño dashboard*/
.contenedor-dash {
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}
.contenido-dash {
  overflow: hidden;
  background-color: var(--color-white);
  display: grid;
  grid-template-areas: 'bolProy' 'generales';
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.grid-contentDash {
  grid-area: bolProy;
  display: grid;
  grid-template-areas: 'publicados proyectos';
  grid-template-columns: 60% calc(40% - 2%);
  gap: 2%;
}

.h4 {
  font-size: 18px;
  border: 0;
  padding: 20px;
  color: var(--color-blue-bajo);
}

.grids-dash {
  background-color: var(--color-white-light);
  box-shadow: var(--boxShadow-dash);
  border-radius: var(--border-radius-boton);
}

.grid-publicados {
  grid-area: publicados;
  overflow: auto;
  width: 100%;
  height: 362px;
}

.grid-proyectos {
  grid-area: proyectos;
  overflow: hidden;
  width: 100%;
  height: 362px;
}

.boletines-general {
  grid-area: generales;
  overflow: hidden;
  display: grid;
}

.contenedor-Bgeneral {
  width: 81.5%;
  height: 210px;
  margin-top: 0.5em;
  margin-left: 30px;
  background-color: var(--color-white-light);
  border-radius: var(--border-radius-boton);
  box-shadow: var(--boxShadow-dash);
  overflow: hidden;
}
.conteiner-encabezado {
  display: inline-flex;
  grid-area: encabezado;
  width: 14em;
}

.redirectList {
  background: transparent;
  border: none;
  color: var(--color-blue-bajo);
  margin-left: 0.8em;
}

.conteiner-bol-gen {
  grid-area: boletines;
  margin-left: 1em;
}

.bol-gen {
  margin-top: 0em;
  width: 10em;
  height: 9em;
  display: inline-flex;
  background-color: var(--color-white);
  margin-left: 2em;
  border: 1px solid var(--color-blue-bajo);
  border-radius: var(--border-radius-boton);
}

.bol {
  margin-left: 3em;
}

.p-bol-gen {
  margin: 0.6em;
  color: var(--color-grey);
}

.digsn-dash {
  display: inline-flex;
  border-bottom: 1px solid var(--color-grey-border);
  margin: 1.5em;
  margin-top: 0.5em;
  width: 410px;
  height: 40px;
}

.digsn-dash2 {
  display: inline-flex;
  border-bottom: 1px solid var(--color-grey-border);
  margin: 1.5em;
  margin-left: 2em;
  margin-top: 0.5em;
  width: 27em;
  height: 40px;
}

.table-dash {
  width: 94%;
  margin-left: 1.5em;
}

.circulo-boletin {
  background-color: var(--color-boton);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.circulo-boletin2 {
  margin-left: 10px;
  margin-right: 10px;
  background-color: var(--color-blue);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.p-boletines {
  color: var(--color-texts);
  font-size: 14px;
  margin-left: 1em;
  font-size: 14px;
}

.p-boletines-2 {
  font-size: 14px;
  color: var(--color-texts);
  font-size: 14px;
}

.p-boletines:hover {
  color: var(--color-boton);
}

.material-icons.md-18 {
  color: var(--color-borde-redireccion);
}

.material-icons.md-18:hover {
  color: var(--color-boton);
}

.contenedor-icon {
  position: absolute;
  margin-top: -6em;
  margin-left: 16em;
}

.btn-bol {
  background-color: transparent;
  width: 3em;
  height: 2em;
  border: none;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .contenedor-dash {
    height: 92vh;
  }

  .grid-contentDash {
    display: inline-table;
    height: 100vh;
  }

  .grid-publicados {
    width: 80%;
    height: 35%;
    padding: 0.4em;
  }
  .h4 {
    margin-left: 1em;
  }
  .circulo-boletin {
    width: 10px;
    height: 10px;
  }

  .circulo-boletin2 {
    width: 10px;
    height: 10px;
  }

  .p-boletines {
    margin-left: 0.5em;
  }
  .contenedor-icon {
    margin-left: 7em;
    margin-top: 0.5em;
  }
  .digsn-dash {
    width: 85%;
    font-size: 12px;
    margin-top: 1em;
    margin-bottom: 0em;
  }

  .disgn1 {
    display: none;
  }

  .grid-proyectos {
    margin-top: 3em;
    margin-left: 2em;
    width: 80%;
    height: 35%;
    padding: 0.4em;
  }

  .digsn-dash2 {
    font-size: 13px;
    margin-top: 0.5em;
    margin-bottom: 0.9em;
    width: 15.5em;
  }

  .boletines-general {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 991px) {
  .contenedor-dash {
    width: auto;
  }
  .grid-contentDash {
    display: inline-table;
  }

  .grid-publicados {
    width: 500px;
    margin-left: 5em;
    padding: 1em;
  }

  .grid-proyectos {
    width: 500px;
    margin-left: 5em;
    margin-top: 4em;
    padding: 1em;
  }

  .digsn-dash2 {
    width: 420px;
  }
  .boletines-general {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .grid-proyectos {
    margin-left: 0em;
    width: 450px;
  }

  .digsn-dash2 {
    width: 90%;
  }

  .boletines-general {
    width: 110vw;
  }
  .bol5 {
    display: none;
  }
}
